import SchemaField from './SchemaField';
import MultiSelectField from './MultiSelectField';

const fields = {
  SchemaField,
  MultiSelectField,
};

export type DefaultFields = typeof fields;

export default fields;
