import { useCallback, useMemo } from 'react';
import type { AutocompleteValue } from '@mui/base';
import { Autocomplete } from '../Autocomplete';
import type { SelectProps, SelectValue } from './types';

function Select<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  Value = string,
>({
  multiple,
  value: _value,
  disableCloseOnSelect = multiple, // do not close popover on select if multiple
  options,
  onChange: _onChange,
  areValueEqual = Object.is,
  valueAccessor,
  disableSearch = true,
  variant,
  ...rest
}: SelectProps<Option, Multiple, DisableClearable, Value>) {
  // mui component uses referential equality of options by default
  const value = useMemo(() => {
    if (Array.isArray(_value)) {
      return options.filter((option) =>
        (_value as Value[]).some((val) => areValueEqual(valueAccessor(option), val)),
      );
    }

    // when value is undefined joyui will assume the component to be uncontrolled and when it is null joyui will assume the component to be controlled
    //https://github.com/mui/material-ui/blob/8aef9ad3fcf89d8061b8beeb04aba18be0510cce/packages/mui-utils/src/useControlled/useControlled.js#L7
    return options.find((option) => areValueEqual(valueAccessor(option), _value as Value)) ?? null;
  }, [_value, areValueEqual, options, valueAccessor]) as AutocompleteValue<
    Option,
    Multiple,
    DisableClearable,
    false
  >;

  const onChange = useCallback(
    (
      _: React.SyntheticEvent,
      nextOption: AutocompleteValue<Option, Multiple, DisableClearable, false>,
    ) => {
      let updatedValue;

      if (Array.isArray(nextOption)) {
        updatedValue = (nextOption as Option[]).map((option) => valueAccessor(option));
      } else if (nextOption as Option | null) {
        updatedValue = valueAccessor(nextOption as Option);
      }

      _onChange?.(updatedValue as SelectValue<Value, Multiple, DisableClearable>, nextOption);
    },
    [_onChange, valueAccessor],
  );

  return (
    <Autocomplete
      disableCloseOnSelect={disableCloseOnSelect}
      disableSearch={disableSearch}
      freeSolo={false}
      multiple={multiple}
      onChange={onChange}
      options={options}
      value={value}
      variant={variant}
      {...rest}
    />
  );
}

export default Select;
