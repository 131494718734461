import type { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import _compact from 'lodash/compact';
import { ARRAY_FIELD_ID_SUFFIX, getId } from '../../../utils/json/utils';
import type { FlattenItemType } from '../types';

export const ID_SEPARATOR = '.';
export const SCHEMA_ID_SEPARATOR = '.properties.';
// eslint-disable-next-line optimize-regex/optimize-regex, prefer-named-capture-group -- needed
export const SPLIT_REGEX = /(\.\w+|\[\d+\])/;

export function getType(schema: JSONSchema7): JSONSchema7TypeName {
  return (Array.isArray(schema.type) ? schema.type[0] : schema.type) ?? 'object';
}

export function matchString(value: string, searchValue: string): boolean {
  return value.toLowerCase().includes(searchValue.toLowerCase());
}

export function getPathSegments(selectedPath: string) {
  const segments: string[] = [];

  let currentPath = '';

  // NOTE: here we are finding all the ancestor node IDs of the clicked node
  const parts = selectedPath.split(SPLIT_REGEX).filter(Boolean);

  parts.forEach((part) => {
    currentPath += part;
    segments.push(currentPath);
  });

  return segments;
}

const formatToTypeMap: Record<string, string> = {
  date: 'date',
  'date-time': 'date-time',
  'file-input': 'file-input',
  'file-uploader': 'file-uploader',
  'json-schema': 'json-schema',
  'ua-formula': 'ua-formula',
};

export function getTypeFromNode(node: FlattenItemType): string {
  return node.originalNode.format
    ? formatToTypeMap[node.originalNode.format] || node.type
    : node.type;
}

export function getTypeFromFormat(format: string | undefined, type?: JSONSchema7TypeName): string {
  return formatToTypeMap[format ?? ''] || type || '';
}

/**
 * id should be `.` seperated and array should end with `[0]`
 * eg: a.b.c[0].d.e
 * returns schema path `a.properties.b.properties.c.items.properties.d.properties.e.properties`
 */
export function generatePropertiesPathFromId({
  pathId,
  separator,
  skipLast,
}: {
  pathId: string;
  separator?: string;
  skipLast?: boolean; // this is used to update the path to point to parent without adding `item.properties` or `properties` at the end
}) {
  const path = _compact(pathId.split(separator ?? ID_SEPARATOR));

  return path
    .reduce<string[]>((result, id, currentIndex) => {
      result.push(getId(id));
      if (!(currentIndex === path.length - 1 && skipLast)) {
        result.push(id.endsWith(ARRAY_FIELD_ID_SUFFIX) ? 'items.properties' : 'properties');
      }
      return result;
    }, [])
    .join(separator ?? ID_SEPARATOR);
}

/**
 * id should be `.` seperated and array should end with `[0]`
 * eg: a.b.c[0].d.e
 * returns uiSchema path `a.b.c.items.d.e`
 */
export const generateUISchemaPathFromId = (pathId: string) => {
  const path = _compact(pathId.split(ID_SEPARATOR));

  return path
    .reduce<string[]>((result, id) => {
      result.push(getId(id));

      if (id.endsWith(ARRAY_FIELD_ID_SUFFIX)) {
        result.push('items');
      }

      return result;
    }, [])
    .join(ID_SEPARATOR);
};
