/* eslint-disable jsx-a11y/no-autofocus -- adding for autofocus support  */
import type { ChangeEvent, FocusEvent } from 'react';
import { useCallback, useMemo } from 'react';
import type { BaseInputTemplateProps } from '@rjsf/utils';
import { getInputProps } from '@rjsf/utils';
import type { InputProps } from '@unifyapps/ui/components/Input';
import { Input } from '@unifyapps/ui/components/Input';
import { Loader } from '@unifyapps/ui/components/Loader';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import getUaOptions from '../../utils/getUaOptions';
import { getUiOptions } from '../../utils/getUiOptions';
import { hasFieldError } from '../../utils/error';

type UaOptionsType = {
  prefixIcon?: {
    name?: string;
  };
  suffixIcon?: {
    name?: string;
  };
  [key: string]: unknown;
};

function BaseInputTemplate(
  props: BaseInputTemplateProps & { value?: string | number; formContext: unknown },
) {
  const {
    schema,
    id,
    options,
    type,
    placeholder,
    required,
    disabled,
    readonly,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    rawErrors,
    hideError,
    uiSchema: _uiSchema,
    hideLabel: _hideLabel,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- formContext is typed
    formContext: _formContext,
    autofocus,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- value is typed
    value,
    registry,
    ...rest
  } = props;

  const onTextChange = useCallback(
    ({ target: { value: val } }: ChangeEvent<HTMLInputElement>) => {
      /**
       * Use the options.emptyValue if it is specified and newVal is also an empty string.
       * undefined is set as initial value is undefined when form loads;
       * setting empty string ('') doesn't triggers 'required' constraint as its valid JSON schema
       */
      onChange(val === '' ? options.emptyValue || undefined : val);
    },
    [onChange, options.emptyValue],
  );

  const onTextBlur = useCallback(
    (event?: FocusEvent<HTMLInputElement>) => {
      if (!event) return;
      onBlur(id, event.target.value);
    },
    [onBlur, id],
  );
  const onTextFocus = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) => onFocus(id, val),
    [onFocus, id],
  );

  const inputProps = useMemo(
    () => ({
      ...rest,
      ...getInputProps(schema, type as string | undefined, options),
    }),
    [options, rest, schema, type],
  );

  const slotProps = useMemo(
    () => ({
      input: {
        id,
        min: inputProps.min,
        max: inputProps.max,
        step: inputProps.step,
      },
    }),
    [id, inputProps.max, inputProps.min, inputProps.step],
  );
  const hasError = hasFieldError(rawErrors, hideError);

  const { size, autocomplete } = getUiOptions({ uiSchema: _uiSchema, registry }) as {
    size?: InputProps['size'];
    autocomplete?: string;
  };

  const { prefixIcon, suffixIcon, loading } = useMemo(
    () => getUaOptions<UaOptionsType>(_uiSchema),
    [_uiSchema],
  );

  const PrefixIcon = getIconFromRegistry(prefixIcon?.name);
  const SuffixIcon = getIconFromRegistry(suffixIcon?.name);

  return (
    <Input
      autoComplete={autocomplete}
      autoFocus={autofocus}
      disabled={disabled || loading}
      endDecoratorComponent={SuffixIcon}
      endDecoratorNode={loading ? <Loader size="xxs" variant="circle" /> : null}
      error={hasError}
      id={id}
      onBlur={onTextBlur}
      onChange={onChangeOverride || onTextChange}
      onFocus={onTextFocus}
      placeholder={placeholder}
      readOnly={readonly}
      required={required}
      size={size ?? 'md'}
      slotProps={slotProps}
      startDecoratorComponent={PrefixIcon}
      value={value as InputProps['value']}
      {...inputProps}
      name={id}
    />
  );
}

export default BaseInputTemplate;
