import { useCallback } from 'react';
import { Box } from '@unifyapps/ui/components/Box';
import { Button } from '@unifyapps/ui/components/Button';
import { getLookupQueryKey } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import { useQueryClient } from '@unifyapps/network/react-query';
import { useRefreshConnectorObjects } from '@unifyapps/network/generated/connection-rest-api/connection-rest-api';
import { useTranslation } from '@unifyapps/i18n/client';
import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';
import type { IconComponentType } from '@unifyapps/ui/types';
import { FooterButton } from '../../components/AutocompleteWithFooter/ListBoxFooter';
import type { LookupWidgetUISchema } from './types';

function RefreshButton({ uiSchema }: { uiSchema: LookupWidgetUISchema<LookupRequest> }) {
  const { t } = useTranslation(['common']);

  const queryClient = useQueryClient();

  const lookupRequest = uiSchema['ui:options']['ua:payload'];

  const { mutate, isPending } = useRefreshConnectorObjects({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: getLookupQueryKey(lookupRequest) });
      },
    },
  });

  const onRefresh = useCallback(() => {
    mutate({
      data: {
        connectionId: lookupRequest.options?.connectionId as string,
        resourceName: lookupRequest.options?.source_resource_name as string,
      },
    });
  }, [mutate, lookupRequest.options?.connectionId, lookupRequest.options?.source_resource_name]);

  return (
    <Button color="brand" loading={isPending} onClick={onRefresh} size="sm" variant="outline">
      {t('common:Actions.RefreshResults')}
    </Button>
  );
}

export function ListBoxFooter<Value>({
  uiSchema,
  footerAction,
  getOptionLabel,
}: {
  uiSchema: LookupWidgetUISchema<LookupRequest>;
  footerAction?: {
    option: Value;
    onSelect: () => void;
    startDecoratorComponent?: IconComponentType;
  };
  getOptionLabel: (option: Value) => string;
}) {
  const showRefresh = uiSchema['ui:options']['ua:refreshable'];

  if (!showRefresh && !footerAction) {
    return null;
  }

  return (
    <Box className="bg-primary p-lg border-secondary sticky bottom-0 flex w-full flex-row justify-between border-t">
      {footerAction ? (
        <FooterButton<Value> footerAction={footerAction} getOptionLabel={getOptionLabel} />
      ) : null}
      {showRefresh ? <RefreshButton uiSchema={uiSchema} /> : null}
    </Box>
  );
}
