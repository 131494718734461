import type { MouseEvent } from 'react';
import { memo, useState } from 'react';
import useEventCallback from '@mui/utils/useEventCallback';
import type { MenuItemProps } from './MenuItem';
import MenuItem from './MenuItem';

export type ConfirmationMenuItemProps = MenuItemProps & {
  confirmationTitle: string;
};

const ConfirmationMenuItem = memo(function ConfirmationMenuItem(props: ConfirmationMenuItemProps) {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { confirmationTitle, title, onClick, caption, ...rest } = props;

  const onClickWrapper = useEventCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (isConfirmationOpen) {
      onClick?.(e);
    } else {
      setIsConfirmationOpen((isOpen) => !isOpen);
    }
  });

  return (
    <MenuItem
      {...rest}
      caption={isConfirmationOpen ? undefined : caption}
      onClick={onClickWrapper}
      title={isConfirmationOpen ? confirmationTitle : title}
    />
  );
});

export default ConfirmationMenuItem;
