import type {
  ObjectFieldTemplatePropertyType,
  ObjectFieldTemplateProps,
  UIOptionsType,
  UiSchema,
} from '@rjsf/utils';
import React, { useMemo } from 'react';
import { groupBy } from 'lodash';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';

export default function VerticalLayout({
  properties,
  layout,
  className,
  uiSchema,
  horizontalClassName,
}: {
  properties: ObjectFieldTemplatePropertyType[];
  layout: (string | string[])[];
  className?: string;
  horizontalClassName?: string;
  uiSchema: ObjectFieldTemplateProps['uiSchema'];
}) {
  const elementsMap = useMemo(() => groupBy(properties, 'name'), [properties]);

  return (
    <Stack className={clsx('gap-y-xl flex', className)}>
      {layout.map((item) => {
        if (Array.isArray(item)) {
          const horizontalItems = item.reduce<JSX.Element[]>((acc, it) => {
            const elemIt = elementsMap[it] as ObjectFieldTemplatePropertyType[] | undefined;
            const uiSchemaForField = uiSchema?.[it] as UiSchema | undefined;
            const elementClassName = (uiSchemaForField?.['ui:options'] as UIOptionsType | undefined)
              ?.className as string;

            if (elemIt?.[0].content) {
              acc.push(
                <Stack className={`min-w-0 flex-1 ${elementClassName}`} key={it}>
                  {elemIt[0].content}
                </Stack>,
              );
            }

            return acc;
          }, []);

          if (!horizontalItems.length) return null;

          return (
            <Stack
              className={clsx('flex flex-row gap-x-2', horizontalClassName)}
              key={item.join('-')}
            >
              {horizontalItems}
            </Stack>
          );
        }
        const elemItem = elementsMap[item] as ObjectFieldTemplatePropertyType[] | undefined;
        return elemItem?.[0].content ? elemItem[0].content : null;
      })}
    </Stack>
  );
}
