import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';
import { formatDate as formatDateFns } from '../../utils/date';

export const enum DateWidgetType {
  Date = 'date',
  DateTime = 'date-time',
}

export function parseDate(value: string | number | undefined, dateFormat: string | undefined) {
  if (value === undefined) return null;
  let dateString: string;

  // if value is a number, it is a epoch time need to convert it to date string
  if (typeof value === 'number' || isValid(parseISO(value))) {
    dateString = dateFormat ? formatDateFns(value, dateFormat) : new Date(value).toJSON();
  } else {
    dateString = value;
  }

  const date = dateFormat ? parse(dateString, dateFormat, new Date()) : new Date(dateString);
  return isValid(date) ? date : null;
}

export function formatDate(date: Date | undefined, dateFormat: string | undefined) {
  if (!date) return undefined;
  return dateFormat ? formatDateFns(date, dateFormat) : date.toJSON();
}

export function getDatePickerValue(
  value: string | number | undefined,
  dateFormat: string | undefined,
) {
  return parseDate(value ?? '', dateFormat) ?? new Date();
}
