import type { ObjectFieldTemplatePropertyType, ObjectFieldTemplateProps } from '@rjsf/utils';
import React, { Fragment, useMemo } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Divider } from '@unifyapps/ui/components/Divider';
import VerticalLayout from '../VerticalLayout';

interface HorizontalSectionLayoutProps {
  properties: ObjectFieldTemplatePropertyType[];
  sections: {
    'ui:title'?: string;
    'ui:description'?: string;
    'ui:layout': (string | string[])[];
    'ui:hideDivider'?: boolean;
    'ui:sectionClassName'?: string;
  }[];
  uiSchema: ObjectFieldTemplateProps['uiSchema'];
}

export default function HorizontalSectionLayout(props: HorizontalSectionLayoutProps) {
  const { properties, sections, uiSchema } = props;

  const filteredSections = useMemo(() => {
    const propertiesNames = properties.map(({ name }) => name);
    return sections.filter(({ 'ui:layout': layout }) =>
      layout.some((item) =>
        Array.isArray(item)
          ? item.some((it) => propertiesNames.includes(it))
          : propertiesNames.includes(item),
      ),
    );
  }, [properties, sections]);

  return (
    <Stack className="gap-y-3xl">
      {filteredSections.map((section, index) => {
        const {
          'ui:title': title,
          'ui:description': description,
          'ui:layout': layout,
          'ui:hideDivider': hideDivider = false,
          'ui:sectionClassName': sectionClassName,
        } = section;

        return (
          <Fragment key={layout.join('-')}>
            <Stack alignItems="start" className="gap-x-4xl" direction="row">
              <Stack className="w-[280px] shrink-0">
                {title ? (
                  <Typography color="text-secondary" variant="text-sm" weight="semi-bold">
                    {title}
                  </Typography>
                ) : null}
                {description ? (
                  <Typography color="text-tertiary" variant="text-sm" weight="regular">
                    {description}
                  </Typography>
                ) : null}
              </Stack>
              <VerticalLayout
                className={`flex-1 ${sectionClassName}`}
                layout={layout}
                properties={properties}
                uiSchema={uiSchema}
              />
            </Stack>
            {hideDivider || index === filteredSections.length - 1 ? null : (
              <Divider color="border-secondary" />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
}
